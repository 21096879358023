import React from "react";
import Onboarding from "./onboarding";
import OnboardingTwo from "./onboardingTwo";

export default function OnboardingPage() {
	return (
		<div style={{ overflowY: "auto", height: "100vh" }}>
			<OnboardingTwo />
		</div>
	);
}
