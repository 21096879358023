import React, { useEffect, useState } from "react";
import { BrowserRouter, redirect } from "react-router-dom";
import { Loader, Text } from "monday-ui-react-core";
import { AppFeatureBoardViewContext } from "monday-sdk-js/types/client-context.type";
import { useDispatch } from "react-redux";

import MainRoutes from "./routes";
import MondayService from "./services/MondayService";
import useModal from "./hooks/components/modalHook";
import Logger from "./utilities/Logger";
import { SIGN_USER_OUT } from "./states/slices/userSlice";
import * as paths from "./routes/paths";
import Button from "./components/Button";
import { WarningIcon } from "./resources/icons";
import { verifyUser } from "./requests/authRequests";
import { MONDAY_UPGRADE_LINK } from "./configs";
import useUser from "./hooks/states/userHook";

function App() {
	const { updateUserContextDetails } = useUser();
	const [isLoading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { ModalComponent, showModal, closeModal } = useModal();

	const showMondayError = () => {
		showModal({
			backdrop: false,
			show: true,
			sticky: true,
			showCloseButton: false,
			width: "360px",
			children: (
				<div className="flex justify-center items-center flex-col space-y-5">
					<div className="text-red-500 flex items-center justify-center gap-3">
						<WarningIcon className="w-12 h-12" />
						<Text className="text-red-500 text-4xl">Oops !!</Text>
					</div>

					<div className="w-full">
						<Text className="text-grayed text-xl text-center font-semibold">
							Something went wrong
						</Text>
						<Text className="text-grayed text-center">Connection to monday failed. Try again</Text>
					</div>

					<div className="w-full flex gap-2 justify-end items-center px-4">
						<Button
							onClick={() => {
								closeModal();
								initUserWithMonday();
							}}
						>
							Try Again
						</Button>

						<Button
							variant="link"
							onClick={() => {
								closeModal();
							}}
						>
							close
						</Button>
					</div>
				</div>
			),
		});
	};

	/**
	 * Initializes user with Monday.com by getting context.
	 * Get's synched data with BE
	 */
	const initUserWithMonday = async () => {
		const mondayService = new MondayService();

		try {
			setLoading(true);
			const context = await mondayService.getContext<{ boardId: string }>();

			if (context?.user?.id && context?.account?.id) {
				try {
					// Verifies if user is authorized
					const verify = await verifyUser(context.user.id, context?.account?.id);

					// Redirects to installation page if Uninstalled or Account not found
					if (verify?.data?.status === "Uninstall") {
						window.parent.location.href = MONDAY_UPGRADE_LINK;
						return;
					}

					if (verify.status === 200) {
						// Proceed to next view if authorized
						await updateUserContextDetails(context as AppFeatureBoardViewContext, true);

						redirect(paths.upload);
					} else {
						// Removes user's locally stored data
						dispatch(SIGN_USER_OUT());
					}
				} catch (error) {
					Logger.error(error);
					dispatch(SIGN_USER_OUT());
				}
			} else {
				// Remove user's locally stored data
				dispatch(SIGN_USER_OUT());
			}
		} catch (error) {
			Logger.error(error);
			showMondayError();
			dispatch(SIGN_USER_OUT());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		// Ignore user context load on onboarding screen
		if (
			window.self.location.pathname !== paths.onboarding ||
			window.location.pathname !== paths.onboarding
		) {
			initUserWithMonday();
		}
	}, []);

	return (
		<>
			{isLoading ? (
				<div className="w-dvw h-dvh gap-4 flex flex-col justify-center items-center">
					<Loader color={Loader.colors.PRIMARY} size={Loader.sizes.LARGE} />
					<Text className="text-white">Verifying with monday.com</Text>
				</div>
			) : (
				<BrowserRouter>
					<MainRoutes />
				</BrowserRouter>
			)}

			<ModalComponent />
		</>
	);
}

export default App;
