import { useDispatch, useSelector } from "react-redux";

import { getUser, SET_ACTIVE_BOARD, SET_USER_ACCOUNT } from "../../states/slices/userSlice";
import Logger from "../../utilities/Logger";
import MondayService from "../../services/MondayService";
import {
	AppFeatureBoardViewContext,
	AppFeatureDashboardWidgetContext,
} from "monday-sdk-js/types/client-context.type";
import { GET_WORKSPACE_BY_BOARD } from "../../resources/queries/board";
import { IWorkspace } from "../../resources/types/responses";

const mondayService = new MondayService();

function useUser() {
	const { userId, accountId, google, boardId, viewId, workspaceId, appFeature, widgetId } =
		useSelector(getUser);
	const dispatch = useDispatch();
	const isAuthorized = !!userId && !!accountId;
	const isGoogleConnected = !!google && !!google.token;
	const GoogleDetails = {
		...google,
		initials:
			google?.name.firstName.toUpperCase() || "" + google?.name.lastName.toUpperCase() || "",
	};
	const driveToken = google?.token;

	const updateUserContextDetails = async (
		context?: AppFeatureBoardViewContext | AppFeatureDashboardWidgetContext,
		setUser = false
	) => {
		try {
			let innerContext = context;
			if (!innerContext) {
				innerContext = (await mondayService.getContext<{
					boardId: string;
				}>()) as AppFeatureBoardViewContext;
			}

			if (setUser) {
				dispatch(
					SET_USER_ACCOUNT({
						accountId: innerContext.account.id,
						userId: innerContext.user.id,
					})
				);
			}

			if ("workspaceId" in innerContext) {
				dispatch(
					SET_ACTIVE_BOARD({
						boardId: `${innerContext.boardId}`,
						workspaceId: innerContext.workspaceId,
						viewId: innerContext.boardViewId,
						appFeature: (innerContext as any)?.appFeature.type,
					})
				);
			}
			if ("widgetId" in innerContext) {
				const workspaceContext = (await mondayService.query<{ workspaces: IWorkspace[] }>(
					GET_WORKSPACE_BY_BOARD(innerContext.boardIds[0])
				)) as any;

				const workspaceIdFound = workspaceContext?.data?.boards[0]?.workspace.id;

				dispatch(
					SET_ACTIVE_BOARD({
						boardId: `${innerContext.boardIds[0]}`,
						widgetId: innerContext?.widgetId,
						appFeature: (innerContext as any)?.appFeature.type,
						...(workspaceIdFound && { workspaceId: workspaceIdFound }),
					})
				);
			}
		} catch (error) {
			Logger.error(error);
		}
	};

	return {
		userId,
		accountId,
		boardId,
		viewId,
		workspaceId,
		GoogleDetails,
		driveToken,
		isAuthorized,
		appFeature,
		isGoogleConnected,
		widgetId,
		updateUserContextDetails,
	};
}

export default useUser;
