import { Column, DefaultCellTypes, Row, TextCell } from "@silevis/reactgrid";
import _ from "lodash";
import Logger from "./Logger";

export const DummyCell = {
	type: "text",
	text: "",
} as TextCell;

export const DummyCellReadOnly = {
	type: "text",
	text: "",
	nonEditable: true,
} as TextCell;

export const DEFAULTS = {
	minimums: {
		column: 26,
		rows: 250,
	},
};

// Function to convert a number to an Excel column header
function numberToExcelHeader(n: number) {
	let result = "";

	while (n > 0) {
		n -= 1; // Adjust for 1-based index
		result = String.fromCharCode((n % 26) + 65) + result;
		n = Math.floor(n / 26);
	}

	return result;
}

// Function to generate Excel headers up to a specified number of columns
export function genExcelHeaders(columnCount: number) {
	const headers = [];
	for (let i = 1; i <= columnCount; i++) {
		headers.push(numberToExcelHeader(i));
	}
	return headers;
}

export function genDefaultColumns(noOfRows = DEFAULTS.minimums.column) {
	const columns: Column[] = [
		{
			columnId: "",
			resizable: false,
			width: 10,
			reorderable: false,
		},
		...genExcelHeaders(noOfRows).map((h) => ({
			columnId: h,
			resizable: true,
			width: 100,
		})),
	];

	return columns;
}

export function genDefaultColumnsData(
	columns: Column[],
	nonEditable = false
): Row<DefaultCellTypes> {
	const rowData = columns.reduce<DefaultCellTypes[]>(
		(cum, cur) => [
			...cum,
			{
				type: "header",
				text: cur.columnId as string,
				nonEditable: false,
			},
		],
		[]
	);

	return {
		rowId: "row-0",
		cells: [...rowData],
	};
}

type TParamRowColumns = {
	count?: number;
	start?: number;
};
export function genDummyColumns(columns?: TParamRowColumns, isReadonly = false) {
	const columnStart = columns?.start || 1;
	const columnCount = columns?.count || DEFAULTS.minimums.column;

	return _.map(
		_.range(columnStart, columnStart + columnCount), // Getting columns for row
		() => (isReadonly ? DummyCellReadOnly : DummyCell)
	);
}

type TParamRows = {
	count?: number;
	start?: number;
};
export function genDummyRows(rows?: TParamRows, columns?: TParamRowColumns, isReadonly = false) {
	const rowStart = rows?.start || 0;
	const rowCount = rows?.count || DEFAULTS.minimums.rows;
	const columnStart = columns?.start;
	const columnCount = columns?.count;

	return _.map(
		_.range(rowStart, rowStart + rowCount), // Getting row range
		(count) => ({
			rowId: `row-${count}`,
			height: 24,
			cells: [
				{ type: "header", text: `${count}`, nonEditable: isReadonly } as DefaultCellTypes, // Row number
				...genDummyColumns({ count: columnCount, start: columnStart }, isReadonly),
			],
			reorderable: false,
		})
	);
}

export function transformToUniqueList(list: Array<string>) {
	try {
		const set = new Set<string>();
		const counter: Record<string, number> = {};

		for (const item of list) {
			if (set.has(item)) {
				const itemOccurence = (counter[item] ?? 0) + 1;
				set.add(`${item}${itemOccurence}`);
				counter[item] = itemOccurence;
			} else {
				set.add(item);
			}
		}

		return Array.from(set);
	} catch (error) {
		Logger.error(error);
		throw error;
	}
}

export function arrayHasDuplicates(arr: Array<string>) {
	const uniqueSet = new Set(arr);
	return uniqueSet.size !== arr.length;
}

export function validateGoogleSheetUrl(url: string): boolean {
	const googleSheetUrlPattern =
		/^https:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9_-]+(\/edit|\/view)?(\?.*)?$/;
	return googleSheetUrlPattern.test(url);
}
