import React from "react";
import Button from "../components/Button";
import useCrisp from "../hooks/components/crispHook";

interface HowToCardProps {
	title?: string;
	description?: string;
	videoUrl: string;
}

const HowToCard: React.FC<HowToCardProps> = ({ title, description, videoUrl }) => {
	return (
		<div className="w-full max-w-2xl mx-auto rounded overflow-hidden shadow-lg my-2 bg-white">
			<div className="relative w-full h-[400px]">
				<iframe
					className="w-full h-full"
					src={videoUrl}
					title={title}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</div>

			{/* Title & Description */}
			{title && (
				<div className="px-6 py-4">
					<div className="font-bold text-2xl mb-2">{title}</div>

					{/* Description */}
					<p className="text-gray-700 text-base">{description}</p>
				</div>
			)}
		</div>
	);
};

const HowToVideos = {
	guide: [
		{
			title: "Onboarding Guide",
			description:
				"Connect your Google Sheets right into a board view or set them up as a new board in the left pane. Your specific sheet loads straight into monday, keeping everything neat and accessible. And guess what? Any edits you make within monday get auto-synced to Google Sheets every minute or instantly with a manual refresh.",
			videoUrl: "https://www.youtube.com/embed/d4GItaK9qTc",
		},
	],
	automation: [
		{
			videoUrl: "https://www.youtube.com/embed/HmOy6o3sYOA",
		},
	],
	customAutomation: [
		{
			videoUrl: "https://www.youtube.com/embed/JGdIFFWSb-I",
		},
	],
	automationGuide: [
		{
			videoUrl: "https://www.youtube.com/embed/mw042TYXKoI",
		},
	],
};

const OnboardingTwo: React.FC = () => {
	useCrisp();
	const howToCards = [
		{
			title: "Attach Google Sheets",
			description:
				"Connect your Google Sheets right into a board view or set them up as a new board in the left pane. Your specific sheet loads straight into monday, keeping everything neat and accessible. And guess what? Any edits you make within monday get auto-synced to Google Sheets every minute or instantly with a manual refresh.",
			videoUrl: "https://www.youtube.com/embed/dQw4w9WgXcQ", // Replace with your actual video URL
		},
	];

	const bookCall = () => {
		const newTab = window.open("https://tidycal.com/lupapps", "_blank");
	};

	return (
		<div className="mb-4 bg-[#cbeec5]" style={{ minWidth: "100vw", minHeight: "100vh" }}>
			<div className="container max-w-[850px] mx-auto px-4 pb-10">
				<div className="text-center py-8">
					<h1 className="text-4xl font-bold text-center mb-6">
						Google Sheets Integration for monday.com
					</h1>
					<div className="w-32 mx-auto mb-4">
						<img src="/logos/gsfi-630×700.png" alt="Logo" />
					</div>
					<h3 className="text-3xl">Welcome aboard!</h3>
				</div>

				<div className="space-y-4 text-center mb-8">
					<p>
						We&apos;re excited to have you! <br /> With this integration, you can instantly{" "}
						<strong>
							embed <span className="underline">live</span> Google Sheets
						</strong>{" "}
						into monday.com, <strong>automate data syncing</strong> between the platforms, and boost
						your workflows—all in one place.
					</p>
				</div>

				{/* Quick Onboarding */}
				<div className="w-full bg-white p-8 mb-8 rounded-xl">
					<div className="text-center font-bold">
						<h3 className="text-3xl">Integrate Google Sheets with monday.com</h3>
					</div>

					{/* Video */}
					<div className="flex flex-col items-center py-4 w-full max-w-2xl mx-auto rounded">
						{HowToVideos.guide.map((video, index) => (
							<HowToCard
								key={index}
								// title={video.title}
								// description={video.description}
								videoUrl={video.videoUrl}
							/>
						))}
						<span className="italic text-sm">Watch this short video to see how it works.</span>
					</div>

					<div className="space-y-8">
						<div>
							<p className="mb-2">Follow these simple steps:</p>

							<ol
								className="!list-inside mb-5"
								style={{
									listStyle: "normal",
									gap: "10px",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<li className="list-item">
									<span className="font-bold">Open the Google Sheet</span> you want to embed and
									copy the shareable URL.
								</li>
								<li className="list-item">
									<span className="font-bold">On monda.com view,</span> open the settings ⚙ button
									on top.
								</li>
								<li className="list-item">
									<span className="font-bold">Paste the URL</span> into the Google Sheets URL text
									box.
								</li>
							</ol>

							<p className="text-center">
								<strong>💡 Tip:</strong> Each embedded view will remain linked to the selected
								Google Sheet view until you update the URL in the settings.
							</p>

							<br />
							<div className="text-center">
								<a
									className="text-center text-[15px] !text-[blue]"
									href="https://gsi.crisp.help/en/article/embed-google-sheets-into-mondaycom-1ssvary"
								>
									Learn more about embedding Google Sheets →
								</a>
							</div>
						</div>
					</div>
				</div>

				{/* Automation Section */}
				<div className="w-full bg-white p-8 mb-8 rounded-xl">
					<div className="text-center font-bold mb-4">
						<h3 className="text-3xl">Google Sheets Automations</h3>
						<h4 className="text-xl">Set It and Forget It! 🚀</h4>
					</div>

					{/* Video */}
					{/* <div className="flex flex-col items-center w-full max-w-2xl mx-auto rounded">
						{HowToVideos.automation.map((video, index) => (
							<HowToCard key={index} videoUrl={video.videoUrl} />
						))}

						<p className="mb-4 italic text-sm">
							Watch to learn how to set up Google Sheets automations to supercharge your data
							syncing between platforms.
						</p>
					</div> */}

					<div className="mt-4">
						{/* <h4 className="text-2xl font-bold mb-2">Google Sheets Automations</h4> */}

						<div className="space-y-5">
							<div>
								<p>
									This integration offers four powerful automation types—perfect for keeping your
									workflows smooth and efficient.
								</p>
								<br />

								<ol className="!list-inside mb-5" style={{ listStyle: "normal" }}>
									<li className="list-item mb-6">
										<span className="font-bold text-[18px]">Google Sheets [EX-SE]:</span> Selective
										Exports, Export specific data, mapped to rows, columns, or cells.
										{/* <ol className="list-inside ml-4 mt-4" style={{ listStyle: "inside" }}>
											<li className="list-item mb-4">
												<strong>Customize your exports</strong> by selecting specific item values
												based on sheet headers or first column values.
											</li>
											<li className="list-item">
												Manipulate data into rows, columns, or even specific cells within your
												Google Sheet.
											</li>
										</ol> */}
									</li>
									<li className="list-item mb-4">
										<span className="font-bold text-[18px]">Google Sheets [EX-FE]:</span> Full Data
										Exports, Export entire boards or groups, create new sheets, or add tabs.
										{/* <ul
											className="list-inside ml-4 mt-4 flex flex-col gap-4"
											style={{ listStyle: "inside" }}
										>
											<li className="list-item">
												Export entire boards or group data into Google Sheets.
											</li>
											<li className="list-item">
												Create <strong>new spreadsheets</strong> in Google Drive or add{" "}
												<strong>new tabs</strong> to existing Google Sheets.
											</li>
											<li className="list-item">
												<strong>Schedule exports</strong> to run automatically or trigger them
												instantly when needed.
											</li>
										</ul> */}
									</li>
									<li className="list-item mb-4">
										<span className="font-bold text-[18px]">Data Imports [IM]:</span> Import
										spreadsheets into new or existing monday.com groups.
									</li>
									<li className="list-item mb-4">
										<span className="font-bold text-[18px]">Create Custom Automations</span>

										<p className="mt-2 ml-4">Want more flexibility?</p>
										<p className="ml-4">
											Build workflows tailored to your needs in just a few steps:
										</p>
										<ol
											className="list-inside ml-8 mt-4 flex flex-col gap-2"
											style={{ listStyle: "normal" }}
										>
											<li className="list-item">
												<strong>Click “Automate”</strong> in the top-right corner of your board and
												select “Add New Automation.”
											</li>
											<li className="list-item">
												<strong>Choose a Trigger:</strong> Select an event to start your automation,
												such as a time interval or when an item is updated.
											</li>
											<li className="list-item">
												<strong>Select an Action:</strong> Choose from 9 powerful options, including
												exporting data to Google Sheets or creating new spreadsheets automatically.
											</li>
										</ol>
									</li>
								</ol>

								<br />
								<p className="text-center">
									<strong>💡 Tip:</strong> Custom automations simplify repetitive tasks, helping you
									focus on what matters most while keeping your data synced seamlessly.
								</p>

								<br />
								<div className="text-center">
									<a
										className="text-center text-[15px] !text-[blue]"
										href="https://gsi.crisp.help/en/"
									>
										Learn more about Google Sheets Automations →
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Content */}
				<div className="mb-10">
					<div className="space-y-16">
						{/* Contact Us */}
						<div className="text-center">
							<h3 className="text-[16px] font-bold">Need Help?</h3>

							<p>
								{" "}
								Can’t find the automation you need? <strong>Chat with us!</strong>
							</p>
							<p className="mb-3">We’re happy to assist or create custom solutions for you.</p>
							<Button onClick={bookCall}>Book a call</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnboardingTwo;
