import React, { FC, ReactNode } from "react";
import { Loader, Button as MondayButton } from "monday-ui-react-core";
import {
	ButtonInputType,
	Size,
} from "monday-ui-react-core/dist/types/components/Button/ButtonConstants";
import { IconType } from "react-icons";
import clsx from "clsx";
import { RobotProps } from "monday-ui-react-core/dist/esm/components/Icon/Icons/components/Robot";

interface IButtonProps {
	type?: "button" | "submit" | "reset";
	variant?: "solid" | "outlined" | "link";
	color?: "danger" | "primary";
	loading?: boolean;
	children?: ReactNode;
	className?: string;
	startIcon?: IconType | FC<RobotProps>;
	onClick?: () => void;
	disabled?: boolean;
	size?: Size;
}

function Button({
	type = "button",
	loading,
	className,
	color = "primary",
	children,
	variant = "solid",
	disabled,
	startIcon,
	onClick,
	size = "sm",
}: IButtonProps) {
	const innerType = MondayButton.types[type.toUpperCase() as keyof typeof ButtonInputType];
	const innerKind =
		variant == "solid"
			? MondayButton.kinds.PRIMARY
			: variant == "outlined"
				? MondayButton.kinds.SECONDARY
				: MondayButton.kinds.TERTIARY;

	return (
		<MondayButton
			type={innerType}
			// loading={loading}
			className={clsx("gap-1", className)}
			kind={innerKind}
			color={color == "danger" ? MondayButton.colors.NEGATIVE : MondayButton.colors.POSITIVE}
			leftIcon={startIcon}
			size={size}
			onClick={onClick}
			disabled={disabled}
		>
			{loading && <Loader size={Loader.sizes.XS} />}
			{children}
		</MondayButton>
	);
}

export default Button;
