import React from "react";
import { Route, Routes } from "react-router-dom";

import * as paths from "./paths";
import AuthLayout from "../layouts/AuthLayout";
import MondayAuth from "../views/auths/ModayAuth";
import UserLayout from "../layouts/UserLayout";
import FileUpload from "../views/upload/FileUpload";
import OnboardingPage from "../views/onboardingPage";
import GoogleIframe from "../views/iframe/googleIframe";

function MainRoutes() {
	return (
		<Routes>
			{/* AUTH PAGES  */}
			<Route path={paths.onboarding} element={<OnboardingPage />} caseSensitive />
			<Route path={paths.home} element={<AuthLayout />} caseSensitive>
				<Route element={<MondayAuth />} index caseSensitive />

				{/* <Route path={paths.home} element={<Navigate to={paths.sign_in} replace />} /> */}
			</Route>

			{/* Authorized Views */}
			<Route path={paths.user_panel} element={<UserLayout />} caseSensitive>
				{/* <Route path={paths.upload} element={<FileUpload />} index caseSensitive /> */}
				<Route path={paths.upload} element={<GoogleIframe />} index caseSensitive />
			</Route>
		</Routes>
	);
}

export default MainRoutes;
